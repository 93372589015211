<template>
    <div class="clientcabin clientcabin-leads">
        <v-container class="pt-8">
            <v-row justify="center">
                <v-col cols="12" lg="10" xl="7" class="py-md-10">
                    <p class="text-sm-h2 text-h3 font-weight-bold text-center">
                        <span class="clientcabin-blue--text">No-Stress</span>
                        Lead Generation While You
                        <span class="clientcabin-blue--text">Focus</span>
                        On Your Business
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish py-md-10">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="d-flex justify-center pl-md-16"
                    >
                        <v-container class="px-0 ml-md-16 pl-md-16">
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            EXPERTS BEHIND
                                        </span>
                                        your ad campaigns
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            LOCAL LEADS
                                        </span>
                                        without lifting a finger
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            CUSTOMIZED STRATEGY
                                        </span>
                                        that attracts customers
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            LEAVE MARKETING
                                        </span>
                                        to the pros
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            GROW YOUR BUSINESS
                                        </span>
                                        with quality leads
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            TAILORED ADS
                                        </span>
                                        for your local business
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            HOT QUALIFIED LEADS
                                        </span>
                                        delivered straight to you
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align-self="center">
                                    <span class="text-h5 font-weight-bold">
                                        <span class="clientcabin-blue--text">
                                            100% FOCUSED
                                        </span>
                                        on maximizing your
                                        <span class="clientcabin-blue--text">
                                            ROI
                                        </span>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="py-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="text-center py-md-10">
                    <p class="text-sm-h2 text-h3 font-weight-bold">
                        Boost Your Business with Targeted Lead Generation!
                    </p>
                    <v-img
                        class="my-sm-16"
                        src="@/assets/img/clientcabin/leads-from-ads/1.png"
                    />
                    <p class="text-sm-h3 text-h4 font-weight-bold">
                        Getting You Hot Leads While You Focus On Your Business
                    </p>
                    <p class="text-sm-h5 text-h6 font-weight-bold py-sm-10">
                        Our expert team specializes in crafting and managing
                        paid ad campaigns that target the right audience for
                        your local business. We work behind the scenes to
                        attract hot, qualified leads directly to you without the
                        stress and hassle. Save time and increase conversions
                        with our proven marketing strategies. Let us handle the
                        heavy lifting of lead generation while you provide the
                        exceptional services your business is known for.
                    </p>
                    <v-btn
                        class="primary pa-sm-12 text-sm-h4 text-h5 pa-8 rounded-lg rounded-sm-xl"
                        :block="$vuetify.breakpoint.smAndDown"
                        @click="onOrder"
                    >
                        Start Getting Leads Now
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <div class="py-md-10 blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container class="pa-0">
                            <v-row justify="center">
                                <v-col class="text-center mb-md-10">
                                    <p
                                        class="text-sm-h3 text-h4 font-weight-bold"
                                    >
                                        Expert Marketing That Provides The
                                    </p>
                                    <p
                                        class="text-sm-h1 text-h2 font-weight-bold clientcabin-blue--text"
                                    >
                                        HOTTEST LEADS
                                    </p>
                                    <p
                                        class="text-sm-h2 text-h3 font-weight-bold"
                                    >
                                        Drive Sales with our Targeted Lead
                                        Generation
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        src="@/assets/img/clientcabin/leads-from-ads/2.png"
                                        contain
                                    />
                                    <p class="text-h4 font-weight-bold">
                                        Get Hot Leads Delivered Directly
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        src="@/assets/img/clientcabin/leads-from-ads/3.png"
                                        contain
                                    />
                                    <p class="text-h4 font-weight-bold">
                                        Increase Revenue with Qualified Leads
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        src="@/assets/img/clientcabin/leads-from-ads/4.png"
                                        contain
                                    />
                                    <p class="text-h4 font-weight-bold">
                                        Save Time with Our Full-Service Ads
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        src="@/assets/img/clientcabin/leads-from-ads/5.png"
                                        contain
                                    />
                                    <p class="text-h4 font-weight-bold">
                                        Boost Your Local Business Visibility
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        src="@/assets/img/clientcabin/leads-from-ads/6.png"
                                        contain
                                    />
                                    <p class="text-h4 font-weight-bold">
                                        Effortless Marketing for Businesses
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        src="@/assets/img/clientcabin/leads-from-ads/7.png"
                                        contain
                                    />
                                    <p class="text-h4 font-weight-bold">
                                        Partner with Experts for Growth
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="py-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="text-center py-md-10">
                    <p class="text-sm-h3 text-h4 font-weight-bold">
                        Discover How To Get Quality Leads with Our Marketing
                        Experts
                    </p>
                    <p
                        class="text-sm-h4 text-h5 font-weight-bold py-sm-10 clientcabin-blue--text"
                    >
                        Tap Into The Power Of Targeted Ads For Your Business
                        Growth
                    </p>
                    <v-btn
                        class="primary pa-sm-12 text-sm-h4 text-h5 pa-8 rounded-lg rounded-sm-xl"
                        :block="$vuetify.breakpoint.smAndDown"
                        @click="onOrder"
                    >
                        Start Getting Leads Now
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <div class="py-md-10 blueish">
            <v-container class="py-md-10">
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="text-center">
                        <p class="text-sm-h3 text-h4 font-weight-bold pb-md-8">
                            Discover How To Get Quality Leads with Our Marketing
                            Experts
                        </p>
                        <p class="text-h6">
                            <b>But, if you sign up now</b>
                            , we'll provide our LeadGen Expertise for JUST $299.
                        </p>
                        <p class="text-h6">
                            <b>This is a limited-time offer</b>
                            so don't miss the chance to boost your business
                            revenue.
                        </p>
                        <p class="text-h6">
                            Take advantage of our
                            <b>expert managed ad services right now</b>
                            , and enjoy our value-packed offer detailed below...
                        </p>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="text-center">
                        <table class="offer-table">
                            <tr>
                                <th>OFFERS</th>
                                <th>ACCESS</th>
                            </tr>
                            <tr>
                                <td>
                                    Get Hot Qualified Leads Delivered to Your
                                    Inbox
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Save on Advertising with Our Managed
                                    Campaigns
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Boost Your Local Business with Targeted
                                    Marketing
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Maximize ROI with Our Expert Ad Management
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Effortless Lead Generation with Hands-Off
                                    Operations
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Gain a Competitive Edge with Advanced
                                    Marketing Strategies
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Secure Exclusive Rights to Your Local Area
                                    Leads
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Crucial Insights with Lead Tracking &
                                    Reporting
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Business Growth Blueprint: Nurture and
                                    Convert Your Leads
                                </td>
                                <td>
                                    <v-btn fab small color="clientcabin-blue">
                                        <v-icon color="white">check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </table>
                        <p
                            class="text-sm-h4 text-h5 font-weight-bold pt-sm-10 pt-6 pb-2"
                        >
                            Your Pathway to Increased Revenue & Business
                            Expansion
                        </p>
                        <v-btn
                            class="primary pa-sm-14 text-sm-h4 text-h5 pa-10 px-4 rounded-lg rounded-sm-xl multiline"
                            :block="$vuetify.breakpoint.smAndDown"
                            @click="onOrder"
                        >
                            <div class="text-h6 text-sm-h4">
                                Unlock Your Business Potential Today
                            </div>
                            <div class="text-body-2 text-sm-h6">
                                Act Quickly Spots Are Filling Fast!
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="pt-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="text-center pt-md-10">
                    <p class="text-sm-h3 text-h4 font-weight-bold">
                        <b>SMART</b>
                        Business Owners Are Seeing
                        <b>FANTASTIC</b>
                        Lead Growth With Our
                        <span class="clientcabin-blue--text">
                            Managed Ads Service And You Can Too!
                        </span>
                        <span class="d-block text-sm-h4 text-h5">
                            (But Don't Delay... Leads Are Waiting!)
                        </span>
                    </p>
                    <v-container class="pa-0 mt-md-10">
                        <v-row justify="center">
                            <v-col cols="12" md="4" class="pa-md-6">
                                <v-img
                                    src="@/assets/img/clientcabin/leads-from-ads/8.png"
                                    contain
                                />
                                <v-img
                                    class="my-2"
                                    src="@/assets/img/clientcabin/leads-from-ads/stars.png"
                                    contain
                                />
                                <p class="text-h5 font-weight-bold mt-4">
                                    DISCOVER! Our Lead Gen System Unveiled!
                                </p>
                                <p class="text-h6">
                                    Get ready for a steady flow of hot leads
                                    with our turnkey paid ads system
                                    (effortlessly capture your ideal clients!)
                                </p>
                            </v-col>
                            <v-col cols="12" md="4" class="pa-md-6">
                                <v-img
                                    src="@/assets/img/clientcabin/leads-from-ads/9.png"
                                    contain
                                />
                                <v-img
                                    class="my-2"
                                    src="@/assets/img/clientcabin/leads-from-ads/stars.png"
                                    contain
                                />
                                <p class="text-h5 font-weight-bold mt-4">
                                    CASE STUDY: Conversions Soar With Expert
                                    Marketing!
                                </p>
                                <p class="text-h6">
                                    Experience the success firsthand — our
                                    skilled ad management has boosted leads for
                                    businesses just like yours!
                                </p>
                            </v-col>
                            <v-col cols="12" md="4" class="pa-md-6">
                                <v-img
                                    src="@/assets/img/clientcabin/leads-from-ads/10.png"
                                    contain
                                />
                                <v-img
                                    class="my-2"
                                    src="@/assets/img/clientcabin/leads-from-ads/stars.png"
                                    contain
                                />
                                <p class="text-h5 font-weight-bold mt-4">
                                    NO More Wasted Ad Spend. Only Results That
                                    Matter!
                                </p>
                                <p class="text-h6">
                                    Forget guesswork and inconsistent results.
                                    Our professional ads management brings you
                                    quality leads without the headache!
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class LeadsFromAds extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get buttonUrl() {
        return this.options?.options?.vip_buy_button_url || '';
    }

    onOrder() {
        if (this.buttonUrl) {
            window.location.href = this.buttonUrl;
        }
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-leads::v-deep {
    background-color: $white;
    color: $black;

    .proposal-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .v-image {
            line-height: 180px;
            max-height: 180px;
            margin-bottom: 40px;
        }
    }

    .offer-table {
        width: 100%;
        font-size: 150%;
        border-collapse: collapse;
        border: 3px solid $clientcabin-blue;
        box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.3);

        th {
            color: white;
            background-color: $clientcabin-blue;
            border: none;
        }

        th,
        td {
            padding: 30px 40px;
        }

        tr:nth-child(odd) {
            background-color: #fff;
        }
        tr:nth-child(even) {
            background-color: #eee;
        }

        td + td {
            text-align: center;
        }
    }

    .multiline {
        .v-btn__content {
            flex-direction: column;
        }
    }
}
</style>
